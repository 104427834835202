import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './css_file/withdraw_management.css';
import { formatDate } from '../utils/utils';
import { getRequest, postRequest } from '../utils/api.js';

function WithdrawManagement() {
    // data from backend
    const [data, setData] = useState([]);
    // condition for onClick statuses button
    const [condition, setCondition] = useState(-1);
    // mutiple select Rows
    const [selectedRows, setSelectedRows] = useState([]);
    // set CurrentMerchantNo
    const [currentWithDrawId, setCurrentWithDrawId] = useState(null);
    // checking withdraw status Modal
    const [isCheckingWithdrawStatusModalOpen, setIsCheckingWithdrawStatusModalOpen] = useState(false);
    // select withdraw status 
    const [selectedStatus, setSelectedStatus] = useState('');
    // setting rejection Modal
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    // columns titles
    const columnTitles = [
        '審核狀態',
        '用戶帳號',
        '用戶名稱',
        '提領時間',
        '提領金額',
        '手續費',
        '銀行資料',
        '通過與否',
        '不通過的原因/備註'
    ];

    // withdraw status
    const withdrawStatuses = ['全部提領申請', '待審核', '已拒絕', '審核通過-待發款', '已發款'];
    // because backend engineer sucks, so need the mapping to rearrange
    // to my surprise his code is really fucking shit. 
    const withdrawStatusMapping = {
        0: '待審核',
        1: '已拒絕',
        2: '審核通過-待發款',
        3: '已發款'
    };
    const withdrawStatusCondtionMapping = {
        0: -1,
        1: 0,
        2: 1,
        3: 2,
        4: 3
    };
    const withdrawStatusReverseMapping = {
        '待審核': 0,
        '已拒絕': 1,
        '審核通過-待發款': 2,
        '已發款': 3
    };
    // for radio buttons
    const radioStatuses = withdrawStatuses.slice(1);

    // useEffect hook for fetching data
    useEffect(() => {
        fetchData();
    }, [condition]);

    const fetchData = async () => {
        try {
            const route = 'admin/Withdraw';
            const queryParameters = { Condition: condition };
            const response = await getRequest(route, queryParameters);
            if (response.result != null) {
                setData(response.result);
            }
            else {
                setData([]);
            }
        } catch (error) {
            console.error('Error fetching withdraw table data:', error);
            throw error;
        }
    };
    // update withdraw status
    async function updateStatus(id, status, reason) {
        try {
            const route = 'admin/Withdraw';
            const queryParameters = {
                Id: id,
                Condition: status,
                Info: reason
            };
            const response = await postRequest(
                route,
                queryParameters
            );

            return response;
        } catch (error) {
            console.error('Failed to update withdraw data status:', error);
            throw error;
        }
    }

    // for multiply select rows
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(data.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelection = (index, e) => {
        if (e.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
        } else {
            setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((row) => row !== index));
        }
    };

    const handleCheckWithdrawStatus = async (id) => {
        setCurrentWithDrawId(id);
        setIsCheckingWithdrawStatusModalOpen(true);
    }

    const handleRadioButtonChange = (status) => (event) => {
        setSelectedStatus(withdrawStatusReverseMapping[status]);
        // console.log("selectedStatus" + selectedStatus);
        // console.log(withdrawStatusReverseMapping[status]);

    }

    const handleConfirmChangingWithdrawStatusButton = async () => {
        setIsCheckingWithdrawStatusModalOpen(false);

        if (selectedStatus === 1) {
            // setIsRejectionModalOpen(true);
        }
        else {
            await updateStatus(currentWithDrawId, selectedStatus);
        }
    }
    const handleCloseWithdrawStatusModal = () => {
        setIsCheckingWithdrawStatusModalOpen(false);
        setSelectedStatus("");
    };



    const handleWithdrawStatusChange = async (rowIndex, newStatus, id) => {
        console.log("newStatus:" + newStatus);
        if (newStatus === 1) {
            // open the modal
            setCurrentWithDrawId(id);
            setIsRejectionModalOpen(true);
        } else {
            await updateStatus(id, newStatus);
        }
    };

    const handleConfirmRejection = async () => {
        // Making an API call to update the status and rejection reason on the backend
        await updateStatus(currentWithDrawId, 1, rejectionReason);
        setIsRejectionModalOpen(false); // close the modal
        setRejectionReason(""); // reset the rejection reason
    };

    // In the return statement, render your table
    return (
        <div>
            <div>
                {withdrawStatuses.map((status, index) => (
                    <button
                        key={index}
                        onClick={() => setCondition(withdrawStatusCondtionMapping[index])}
                        className="status-button"
                    >
                        {status}
                    </button>
                ))}
            </div>
            <div>
                <table className="withdraw-management-table">
                    <thead>
                        <tr>
                            <th className="column-checkbox"><input type="checkbox" onChange={handleSelectAll} /></th>
                            <th className="column-no">No.</th>
                            {Array(11).fill(null).map((_, index) => <th className={`column${index}`} key={index}>{columnTitles[index]}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(rowIndex)}
                                        onChange={(e) => handleRowSelection(rowIndex, e)}
                                    />
                                </td>
                                <td>{rowIndex + 1}</td>
                                <td>{withdrawStatusMapping[row.Status]}</td>
                                <td>{row.ZhezheAccount}</td>
                                <td>{row.Name}</td>
                                <td>{formatDate(row.CreatedAt)}</td>
                                <td>{'$' + row.Amount}</td>
                                <td>{'$' + row.Fee}</td>
                                <td>{row.BankCode + "-" + row.BankAccount}</td>
                                <td className="withdraw-center-cell">
                                    {row.Status === 0 ?
                                        (
                                            <>
                                                <button onClick={() => handleWithdrawStatusChange(rowIndex, 2, row.Id)} className="passed-button">通過</button>
                                                <button onClick={() => handleWithdrawStatusChange(rowIndex, 1, row.Id)} className="rejected-button">拒絕</button>
                                            </>
                                        ) : (<button onClick={() => handleCheckWithdrawStatus(row.Id)} className="change-orderStaus-button">更改提領狀態</button>)}
                                </td>
                                <td>{row.Info}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={isRejectionModalOpen} onRequestClose={() => setIsRejectionModalOpen(false)}
                className="modal-rejection-content" >
                <h2>不通過原因/備註</h2>
                <textarea value={rejectionReason} onChange={e => setRejectionReason(e.target.value)} />
                <button onClick={() => handleConfirmRejection()}>Confirm Rejection</button>
            </Modal>
            <Modal isOpen={isCheckingWithdrawStatusModalOpen} onRequestClose={
                handleCloseWithdrawStatusModal
            }
                className="modal-change-withdrawStatus-content">
                <h2>更改提領狀態</h2>
                {radioStatuses.map(status => (
                    <div key={status}>
                        <input type="radio" id={status} name="status" checked={selectedStatus === withdrawStatusReverseMapping[status]} onChange={handleRadioButtonChange(status)} />
                        <label htmlFor={status}>{status}</label>
                    </div>
                ))}
                <button onClick={handleConfirmChangingWithdrawStatusButton}>Confirm</button>
            </Modal>
        </div>
    );
}

export default WithdrawManagement;
