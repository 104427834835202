import axios from 'axios';

// prod
// const prefixUrl = 'https://api.thala.io/';
// dev
const prefixUrl = 'https://api-dev.thala.io/';
const token = sessionStorage.getItem('token');

export const getRequest = async (route, queryParameters) => {
    try {
        const requestUrl = prefixUrl + route;
        const response = await axios.get(requestUrl, {
            params: queryParameters,
            headers: {
                'Authorization': token,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to request data:', error);
        throw error;
    }
};

export const postRequest = async (route, queryParameter) => {
    try {
        const requestUrl = prefixUrl + route;
        const response = await axios.post(
            requestUrl,
            queryParameter,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Failed to post data:', error);
        throw error;
    }
};