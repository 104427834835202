import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css_file/KYC_management.css';
import { formatDate } from '../utils/utils.js';
import { getRequest, postRequest } from '../utils/api.js';

function KYCManagement() {
    const [KYCData, setKYCData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState({ photoFront: '', photoBack: '' });
    // setting rejection Modal
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    // set CurrentMerchantNo
    const [currentUserId, setcurrentUserId] = useState(null);
    const columnTitles = [
        '',
        'id',
        '審核狀態',
        '用戶帳號',
        '用戶名稱',
        '申請日期',
        '送審資料',
        '通過與否',
        '不通過的原因/備註',
    ];

    useEffect(() => {
        fetchKYCData();

    }, []);

    const fetchKYCData = async () => {
        try {
            const response = await getRequest('admin/KYCList', {
            });
            if (response.result != null)
                setKYCData(response.result);
            else {
                setKYCData([]);
            }
        } catch (error) {
            console.error('Error fetching KYC data:', error);
        }
    };

    async function updateStatus(userId, status, rejectReason) {
        try {
            const route = 'admin/KYCApply';
            const queryParameters = {
                user_id: userId,
                status: status,
                info: rejectReason,
            };
            const response = await postRequest(
                route,
                queryParameters,
            );
            if (response.code === 0) {

                toast.success("變更成功");
                const updatedData = [...KYCData];
                updatedData[currentIndex].status = status;
                setKYCData(updatedData);
            }
            else {
                toast.error("Error message: " + response.result);
            }


            return response;
        } catch (error) {
            console.error('Failed to update user status:', error);
            toast.error("Error message: " + error);
            throw error;
        }
    }

    const handleSelectAll = (e) => {

        if (e.target.checked) {
            setSelectedRows(KYCData.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelection = (index, e) => {
        if (e.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
        } else {
            setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((row) => row !== index));
        }
    };
    const handleKYCButtonClick = (photoFront, photoBack) => {
        setSelectedPhotos({ photoFront, photoBack });
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleStatusChange = async (rowIndex, newStatus, userId) => {
        setCurrentIndex(rowIndex);
        // for rejection
        setcurrentUserId(userId);
        if (newStatus === 2) {
            setIsRejectionModalOpen(true);

        }
        else { await updateStatus(userId, newStatus); }


    };

    const handleConfirmRejection = async () => {
        // Making an API call to update the status and rejection reason on the backend
        await updateStatus(currentUserId, 2, rejectionReason);
        setIsRejectionModalOpen(false); // close the modal
        setRejectionReason(""); // reset the rejection reason
    };

    const getStatusText = (status) => {
        switch (status) {
            case 0:
                return '通過';
            case 1:
                return '待審核';
            case 2:
                return '拒絕';
            default:
                return 'unknown';
        }
    };


    return (
        <div>
            <table className="field1-table">
                <thead>
                    <tr>
                        {columnTitles.map((title, index) => (
                            <th key={index}>
                                {index === 0 ? <input type="checkbox" onChange={handleSelectAll} /> : title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {KYCData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.includes(rowIndex)}
                                    onChange={(e) => handleRowSelection(rowIndex, e)}
                                />
                            </td>
                            <td>{row.id}</td>
                            <td>{getStatusText(row.status)}</td>
                            <td>{row.zhezhe_account}</td>
                            <td>{row.name}</td>
                            <td>{formatDate(row.created_at)}</td>
                            <td className="center-cell">
                                <button onClick={() => handleKYCButtonClick(row.photo_front, row.photo_back)}>查看</button>
                            </td>

                            <td className="center-cell">
                                {row.status === 2 ? (
                                    <span className="failed">拒絕</span>
                                ) : row.status === 0 ? (
                                    <span className="passed">通過</span>
                                ) : (
                                    <>
                                        <button onClick={() => handleStatusChange(rowIndex, 0, row.user_id)} className="passed-button">通過</button>
                                        <button onClick={() => handleStatusChange(rowIndex, 2, row.user_id)} className="rejected-button">拒絕</button>
                                    </>
                                )}
                            </td>
                            <td>{row.info}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Photos Modal"
                className="KYC-modal-content">
                <h2>身分證資料</h2>
                <button onClick={closeModal} className="close-button">Close</button>
                <div className="KYC-modal-images">
                    <img src={selectedPhotos.photoFront} alt="" />
                    <img src={selectedPhotos.photoBack} alt="" />
                </div>
            </Modal>
            <Modal isOpen={isRejectionModalOpen} onRequestClose={() => setIsRejectionModalOpen(false)}
                className="modal-rejection-content" >
                <h2>不通過原因/備註</h2>
                <textarea value={rejectionReason} onChange={e => setRejectionReason(e.target.value)} />
                <button onClick={() => handleConfirmRejection()}>Confirm Rejection</button>
            </Modal>
            <ToastContainer position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
        </div>
    );
}

export default KYCManagement;