export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};
export const formatOrderTime = (startTime, orderLength) => {
    const endTime = startTime + orderLength;
    return `${startTime.toString().padStart(2, '0')}:00~${endTime.toString().padStart(2, '0')}:00`;
}

export const formatTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
};

export const couponTargetGroup = (group) => {
    switch (group) {
        case 0:
            return '全部對象';
        case 4:
            return '已完成';
        case 5:
            return '取消待審核';
        case 6:
            return '已取消';
        case 7:
            return '取消-不通過';
        default:
            return 'unknown';
    }
};

export const getTimeStampinSec = (time) => {
    const dateObj = new Date(time);
    const timestamp = dateObj.getTime() / 1000;
    return timestamp;
};