import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import Modal from 'react-modal';
import KYCManagement from './management_table/KYC_management.js';
import OrderManagement from './management_table/order_management.js';
import CouponManagement from './management_table/coupon_management.js';
import WithdrawManagement from './management_table/withdraw_management.js';

Modal.setAppElement('#root');

const token = sessionStorage.getItem('token');

function TopBar({ title, username, onLogout }) {
    return (
        <div className="top-bar">
            <div className="title">{title}</div>
            <div className="user-info">
                <span>{username}</span>
                <button onClick={onLogout}>登出</button>
            </div>
        </div>
    );
}
function LeftBar({ items, activeItem, onItemClick }) {
    return (
        <div className="left-bar">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`left-bar-item ${index === activeItem ? 'active' : ''}`}
                    onClick={() => onItemClick(index)}
                >
                    {item}
                </div>
            ))}
        </div>
    );
}


function LandingPage() {
    const navigate = useNavigate();
    const username = ("User: " + sessionStorage.getItem('username')) || 'Unknown User';
    const [activeItem, setActiveItem] = useState(0);
    const leftBarItems = ['身份認證', '訂單管理', '優惠券', '提領管理'];

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const handleLogout = () => {
        // Clear user info
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('username');

        // Redirect to login page
        navigate('/');
    };

    const renderContent = () => {
        switch (activeItem) {
            case 0:
                return <KYCManagement token={token} />;
            case 1:
                return <OrderManagement token={token} />;
            case 2:
                return <CouponManagement token={token} />;
            case 3:
                return <WithdrawManagement token={token} />;
            default:
                return <div>No content</div>;
        }
    };


    return (
        <div className="landing-page">
            <TopBar title="Thala" username={username} onLogout={handleLogout} />
            <div className="main">
                <LeftBar items={leftBarItems} activeItem={activeItem} onItemClick={handleItemClick} />
                <div className="content">
                    <h2>{leftBarItems[activeItem]}</h2>
                    <div className="content">{renderContent()}</div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
