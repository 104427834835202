import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './css_file/order_management.css';
import { formatDate, formatOrderTime } from '../utils/utils';
import { getRequest, postRequest } from '../utils/api.js';

function OrderManagement() {
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [condition, setCondition] = useState(0);
    // description Modal
    const [description, setDescription] = useState({ description: '' });
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    // cancel description Modal
    const [cancelDescription, setCancelDescription] = useState({ description: '' });
    const [isCancelDescriptionModalOpen, setIsCancelDescriptionModalOpen] = useState(false);
    // setting rejection Modal
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    // setting order status Modal
    const [isSettingOrderStatusModalOpen, setIsSettingOrderStatusModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    // set CurrentMerchantNo
    const [currentMerchantNo, setCurrentMerchantNo] = useState(null);

    const statuses = ['全部訂單', '待完成', '已完成', '取消待審核', '已取消', '取消不通過'];
    const conditionMapping = {
        0: 0,
        1: 1,
        2: 4,
        3: 5,
        4: 6,
        5: 7,
    };
    const statusMapping = {
        '待完成': 1,
        '已完成': 4,
        '取消待審核': 5,
        '已取消': 6,
        '取消不通過': 7,
    };
    const radioStatuses = statuses.slice(1);
    const columnTitles = [
        '訂單狀態',
        '下單日期',
        '訂單編號',
        '預約人',
        '服務商',
        '預約日期',
        '預約時間',
        '總時間',
        '優惠券折扣',
        '總金額',
        '內容與地點說明',
        '支付時間',
        '支付方式',
        '電子信箱',
        '取消原因',
        '',
        '不通過原因/備註',
    ];

    useEffect(() => {
        fetchData();
    }, [condition]);

    const fetchData = async () => {
        try {
            const route = 'admin/AppointmentList';
            const queryParameters = { condition: condition };
            const response = await getRequest(route, queryParameters);
            if (response.result != null) {
                setData(response.result);
            }
            else {
                setData([]);
            }
        } catch (error) {
            console.error('Error fetching order management data:', error);
            throw error;
        }
    };

    async function updateStatus(merchantNo, status, reason) {
        try {
            const route = 'admin/AppointmentList';
            const queryParameters = {
                merchantOrderNo: merchantNo,
                condition: status,
                info: reason
            };
            const response = await postRequest(
                route,
                queryParameters
            );

            return response;
        } catch (error) {
            console.error('Failed to update user status:', error);
            throw error;
        }
    }

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return '待完成';
            case 4:
                return '已完成';
            case 5:
                return '取消待審核';
            case 6:
                return '已取消';
            case 7:
                return '取消-不通過';
            default:
                return 'unknown';
        }
    };

    const handleDescriptionButtonClick = (description) => {
        setDescription({ description });
        setIsDescriptionModalOpen(true);
    };
    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const handleCancelDescriptionButtonClick = (description) => {
        setCancelDescription({ description });
        setIsCancelDescriptionModalOpen(true);
    };
    const closeCancelDescriptionModal = () => {
        setIsCancelDescriptionModalOpen(false);
    };


    const handleStatusChange = async (rowIndex, newStatus, merchantNo) => {
        // Update the user status in the data array
        // This can also be done by making an API call to update the status on the backend
        // if the status is 'cancel pending'
        if (newStatus === 7) {
            // open the modal
            setCurrentMerchantNo(merchantNo);
            setIsRejectionModalOpen(true);
        } else {
            //  making an API call to update the status on the backend
            await updateStatus(merchantNo, newStatus);
        }
    };

    const handleConfirmRejection = async () => {
        // Making an API call to update the status and rejection reason on the backend
        await updateStatus(currentMerchantNo, 7, rejectionReason);
        setIsRejectionModalOpen(false); // close the modal
        setRejectionReason(""); // reset the rejection reason
    };



    const handleClickSettingOrderStatus = (merchantNo) => {
        setCurrentMerchantNo(merchantNo);
        setIsSettingOrderStatusModalOpen(true);
    }

    const handleRadioButtonChange = (status) => (event) => {
        setSelectedStatus(statusMapping[status]);
    }

    const handleConfirmChangingOrderStatusButton = async () => {
        setIsSettingOrderStatusModalOpen(false);
        if (selectedStatus === 7) {
            setIsRejectionModalOpen(true);
        }
        else {
            await updateStatus(currentMerchantNo, selectedStatus);
        }
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(data.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    };

    const handleRowSelection = (index, e) => {
        if (e.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
        } else {
            setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((row) => row !== index));
        }
    };

    return (
        <div>
            <div>
                {statuses.map((status, index) => (
                    <button
                        key={index}
                        onClick={() => setCondition(index === 0 ? index : conditionMapping[index])}
                        className="status-button"
                    >
                        {status}
                    </button>
                ))}
            </div>
            <div>
                <table className="order-management-table">
                    <thead>
                        <tr>
                            <th className="column-checkbox"><input type="checkbox" onChange={handleSelectAll} /></th>
                            <th className="column-no">No.</th>
                            {Array(17).fill(null).map((_, index) => <th className={`column${index}`} key={index}>{columnTitles[index]}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(rowIndex)}
                                        onChange={(e) => handleRowSelection(rowIndex, e)}
                                    />
                                </td>
                                <td>{rowIndex + 1}</td>
                                <td>{getStatusText(row.Status)}</td>
                                <td>{formatDate(row.CreatedAt)}</td>
                                <td>{row.MerchantOrderNo}</td>
                                <td>{row.CustomerName + "\n" + row.CustomerAccount}</td>
                                <td>{row.RentUserName + "\n" + row.RentUserAccount}</td>
                                <td>{formatDate(row.ScheduleDate)}</td>
                                <td>{formatOrderTime(row.StartTime, row.OrderTimeLength)}</td>
                                <td>{row.OrderTimeLength + "小時"}</td>
                                <td>{row.CouponsName === "" ? `no coupon\n${row.Discount}` : `${row.CouponsName}\n${row.Discount}`}</td>
                                <td>{row.DiscountedPrice}</td>
                                <td className="center-cell">
                                    <button onClick={() => handleDescriptionButtonClick(row.Info)}>查看</button>
                                </td>

                                <td>{formatDate(row.PayTime)}</td>
                                <td>{row.PaymentType}</td>
                                <td >Placeholder</td>
                                <td className="center-cell">
                                    {(row.Status === 5 || row.Status === 6 || row.Status === 7) && <button onClick={() => handleCancelDescriptionButtonClick(row.CancelInfo)}>查看</button>}
                                </td>
                                <td className="center-cell">
                                    {row.Status === 5 ?
                                        (
                                            <>
                                                <button onClick={() => handleStatusChange(rowIndex, 6, row.MerchantOrderNo)} className="passed-button">通過</button>
                                                <button onClick={() => handleStatusChange(rowIndex, 7, row.MerchantOrderNo)} className="rejected-button">拒絕</button>
                                            </>
                                        ) : (<button onClick={() => handleClickSettingOrderStatus(row.MerchantOrderNo)} className="change-orderStaus-button">更改訂單狀態</button>)}
                                </td>
                                <td>{row.AdminCancelInfo}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Modal isOpen={isDescriptionModalOpen} onRequestClose={closeDescriptionModal} contentLabel="descrition modal"
                    className="modal-content">
                    <h2>內容與地點說明</h2>
                    <button onClick={closeDescriptionModal} className="close-button">Close</button>
                    <div>
                        {description.description}
                    </div>
                </Modal>
                <Modal isOpen={isCancelDescriptionModalOpen} onRequestClose={closeCancelDescriptionModal} contentLabel="descrition modal"
                    className="modal-content">
                    <h2>取消原因</h2>
                    <button onClick={closeCancelDescriptionModal} className="close-button">Close</button>
                    <div>
                        {cancelDescription.description}
                    </div>
                </Modal>
                <Modal isOpen={isRejectionModalOpen} onRequestClose={() => setIsRejectionModalOpen(false)}
                    className="modal-rejection-content" >
                    <h2>不通過原因/備註</h2>
                    <textarea value={rejectionReason} onChange={e => setRejectionReason(e.target.value)} />
                    <button onClick={() => handleConfirmRejection()}>Confirm Rejection</button>
                </Modal>
                <Modal isOpen={isSettingOrderStatusModalOpen} onRequestClose={() => setIsSettingOrderStatusModalOpen(false)}
                    className="modal-change-orderStatus-content">
                    <h2>更改訂單狀態</h2>
                    {radioStatuses.map(status => (
                        <div key={status}>
                            <input type="radio" id={status} name="status" value={statusMapping[status]} checked={selectedStatus === statusMapping[status]} onChange={handleRadioButtonChange(status)} />
                            <label htmlFor={status}>{status}</label>
                        </div>
                    ))}
                    <button onClick={handleConfirmChangingOrderStatusButton}>Confirm</button>
                </Modal>
            </div>
        </div>
    );
}

export default OrderManagement;
